import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import Img from 'gatsby-image/withIEPolyfill';
import Button from '../../../../components/Button';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import SectionWrapper from '../../../../components/SectionWrapper';
import styles from './styles.module.sass';

const TickItem = ({text, fluid}) => (
    <div className={styles.tickItem}>
        <div className={styles.tickWrap}>
            <Img imgStyle={{ objectFit: 'contain', width: '37px', height: '36px' }}
                objectFit='contain'
                className={styles.imageTick}
                fluid={fluid}/>
        </div>
        <span className={styles.tickText}>{i18n(text)}</span>
    </div>
);

export default () => (
  <StaticQuery
    query={graphql`
        query {
            tick: imageSharp (fluid: { originalName: { eq: "fleet-landing-price-tick.png" }}) {
                fluid(maxWidth: 24, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
            twenty4seven: imageSharp (fluid: { originalName: { eq: "vc-landing-price-24-7.png" }}) {
                fluid(maxWidth: 37, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
            chat: imageSharp (fluid: { originalName: { eq: "vc-landing-price-chat.png" }}) {
                fluid(maxWidth: 37, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
            clock: imageSharp (fluid: { originalName: { eq: "vc-landing-price-clock.png" }}) {
                fluid(maxWidth: 37, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
            doc: imageSharp (fluid: { originalName: { eq: "vc-landing-price-doc.png" }}) {
                fluid(maxWidth: 37, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
            phone: imageSharp (fluid: { originalName: { eq: "vc-landing-price-phone.png" }}) {
                fluid(maxWidth: 37, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
            search: imageSharp (fluid: { originalName: { eq: "vc-landing-price-search.png" }}) {
                fluid(maxWidth: 37, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
            tool: imageSharp (fluid: { originalName: { eq: "vc-landing-price-tool.png" }}) {
                fluid(maxWidth: 37, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
            video: imageSharp (fluid: { originalName: { eq: "vc-landing-price-video.png" }}) {
                fluid(maxWidth: 37, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
            devices: imageSharp (fluid: { originalName: { eq: "vc-landing-price-devices.png" }}) {
                fluid(maxWidth: 75, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
            drivelogo: imageSharp (fluid: { originalName: { eq: "Drive-Logo-Text-2x.png" }}) {
                fluid(maxHeight: 42, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
            fleetlogo: imageSharp (fluid: { originalName: { eq: "Fleet-Logo-Text.png" }}) {
                fluid(maxHeight: 42, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
            vclogo: imageSharp (fluid: { originalName: { eq: "VC-Logo-Text.png" }}) {
                fluid(maxHeight: 42, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    `}
    render={(data) => {
        const [ paidby, setPaidby ] = React.useState('customer');
        return (
        <>
            <SectionWrapper className={styles.container}>
                <Grid item container className={styles.titleContainer}>
                    <Typography variant='h2' color='dark' weight='bold'
                        align='center' className={styles.pageTitle}>
                        {i18n('Benyt digitale tjenester helt gratis. Betal kun hvis du vil.')}
                    </Typography>
                    <Typography variant='subtitle2' color='dark' weight='light' 
                        align='center' className={styles.pageSubtitle}>
                        {i18n('Alt inkluderet. Ingen binding.')}
                    </Typography>
                    <div className={styles.buttonsWrapper}>
                        <Button className={clsx(styles.button, paidby !== 'customer' ? styles.buttonInactive : '')} onClick={() => setPaidby('customer')}>
                            {i18n('Kunden betaler')}
                        </Button>

                        <Button className={clsx(styles.button, paidby === 'customer' ? styles.buttonInactive : '')} onClick={() => setPaidby('workshop')}>
                            {i18n('Værkstedet betaler')}
                        </Button>
                    </div>
                </Grid>
            </SectionWrapper>

            <SectionWrapper className={clsx(styles.container, styles.pricingSection)}>
                <Grid item container alignItems={'stretch'} className={styles.tablesWrapper}>
                    
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5} className={styles.pricingWrap}>
                        <div className={styles.pricingTable}>
                            
                            <div className={styles.tableHeader}>
                                {i18n('Gratis digitale værktøj')}
                            </div>

                            <div className={styles.tableContent}>
                                <Img imgStyle={{ objectFit: 'contain', width: 'auto', height: '42', maxWidth: '100%' }}
                                    objectFit='contain'
                                    className={styles.imageLogo}
                                    fluid={data.vclogo.fluid}/>
                                <Typography variant='h3'
                                    color='dark'
                                    weight='bold'
                                    className={styles.columnTitle}>
                                    {i18n('Til din forretning')}
                                </Typography>
                                <div className={styles.tickWrapper}>
                                    <TickItem fluid={data.video.fluid} text={'Videooptagelse'}/>
                                    <TickItem fluid={data.doc.fluid} text={'Digital dokumentation'}/>
                                    <TickItem fluid={data.chat.fluid} text={'Kommunikation'}/>
                                    <TickItem fluid={data.clock.fluid} text={'Planlagt vedligeholdelse baseret på tid'}/>
                                </div>

                                <Typography variant='h3'
                                    color='dark'
                                    weight='bold'
                                    className={clsx(styles.columnTitle, styles.lastTitle)}>
                                    {i18n('Gratis tjenester til kunderne')}
                                </Typography>

                                <Grid container>
                                    <Grid item xs={12} sm={6} md={6} lg={6} className={styles.logoLeftWrap}>
                                        <Img imgStyle={{ objectFit: 'contain', width: 'auto', height: '42', maxWidth: '100%' }}
                                            objectFit='contain'
                                            className={styles.imageLogo}
                                            fluid={data.drivelogo.fluid}/>
                                        <Typography variant='subtitle2' color='dark' weight='light' 
                                            align='left' className={styles.logoSubtitle}>
                                            {i18n('Bil-app til enhver billist')}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <Img imgStyle={{ objectFit: 'contain', width: 'auto', height: '42', maxWidth: '100%' }}
                                            objectFit='contain'
                                            className={styles.imageLogo}
                                            fluid={data.fleetlogo.fluid}/>
                                        <Typography variant='subtitle2' color='dark' weight='light' 
                                            align='left' className={styles.logoSubtitle}>
                                            {i18n('Overblik over køretøjer på den nemmeste måde')}
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </div>

                            <div className={styles.tableFooter}>
                                <span className={styles.footerHighlight}>0 kr.</span><br/>Digital plan<br/>&nbsp;
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7} className={clsx(styles.pricingWrap, styles.rightPricing)}>
                        <div className={styles.pricingTable}>
                            
                            <div className={clsx(styles.tableHeader, paidby !== "customer" ? styles.tightHeader : '')}>
                                {paidby === "customer"
                                    ? `${i18n('Kunderne betaler abonnementet')}`
                                    : `${i18n('Værkstedet betaler for at tilslutte køretøjer. Gratis adgang til kunderne.')}`
                                }
                            </div>

                            <div className={styles.tableContent}>
                                <Img imgStyle={{ objectFit: 'contain', width: 'auto', height: '42', maxWidth: '100%' }}
                                    objectFit='contain'
                                    className={styles.imageLogo}
                                    fluid={data.vclogo.fluid}/>
                                <Typography variant='h3'
                                    color='dark'
                                    weight='bold'
                                    className={styles.columnTitle}>
                                    {i18n('Til din forretning')}
                                </Typography>
                                <Typography variant='subtitle2'
                                    color='dark'
                                    weight='bold'
                                    className={styles.columnSubtitle}>
                                    {i18n('Alle funktioner fra Digital+')}
                                </Typography>
                                <div className={styles.tickWrapper}>
                                    <TickItem fluid={data.twenty4seven.fluid} text={'Forbindelse til køretøjet 24/7'}/>
                                    <TickItem fluid={data.search.fluid} text={'Daglige fejlsøgninger og vigtige advarsler'}/>
                                    <TickItem fluid={data.tool.fluid} text={'Planlagt vedligeholdelse baseret på bildata'}/>
                                </div>
                                
                                <Typography variant='h3'
                                    color='dark'
                                    weight='bold'
                                    className={clsx(styles.columnTitle, styles.lastTitle)}>
                                    {i18n('Opgrader til et connected car abonnement når som helst')}
                                </Typography>

                                <Grid container>
                                    <Grid item xs={6} sm={7} md={6} lg={7} className={styles.logoLeftWrap}>
                                        <Img imgStyle={{ objectFit: 'contain', width: 'auto', height: '42', maxWidth: '100%' }}
                                            objectFit='contain'
                                            className={styles.imageLogo}
                                            fluid={data.drivelogo.fluid}/>
                                        <Typography variant='subtitle2' color='dark' weight='light' 
                                            align='left' className={styles.logoSubtitle}>
                                            {i18n('Tryghed og minimal indsats på bil-relaterede opgaver')}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6} sm={5} md={6} lg={5}>
                                        <Img imgStyle={{ objectFit: 'contain', width: 'auto', height: '42', maxWidth: '100%' }}
                                            objectFit='contain'
                                            className={styles.imageLogo}
                                            fluid={data.fleetlogo.fluid}/>
                                        <Typography variant='subtitle2' color='dark' weight='light' 
                                            align='left' className={styles.logoSubtitle}>
                                            {i18n('En simpel og automatiseret løsning til erhvervskunder.')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className={styles.tableFooter}>
                                <Grid container>
                                    <Grid item xs={6} sm={7} md={6} lg={7}>
                                        <span className={styles.footerHighlight}>60 kr.</span><br/>pr.bil pr. måned<br/>ekskl. moms
                                    </Grid>
                                    <Grid item xs={6} sm={5} md={6} lg={5}>
                                        <span className={styles.footerHighlight}>100 kr.</span><br/>pr.bil pr. måned<br/>ekskl. moms
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>

                </Grid>
            </SectionWrapper>

            <SectionWrapper className={styles.ctaSection}>
                <Grid item container className={styles.titleContainer}>
                    <Typography variant='h2' color='dark' weight='bold'
                        align='center' className={styles.pageTitle}>
                        {i18n('Kom i gang i dag ved at blive en partner')}
                    </Typography>

                    <Typography variant='subtitle2' color='dark' weight='light' 
                        align='center' className={styles.pageSubtitle}>
                        {i18n('Vi hjælper dig med at vælge den bedste løsning til din virksomhed.')}
                    </Typography>

                    <div className={styles.buttonContainer}>
                        <Link to={`${i18n('_url:landing-vc-signup')}`}
                        style={{ textDecoration: 'none'}}>
                            <Button className={styles.button}>
                                {i18n('Get started')}
                            </Button>
                        </Link>
                    </div>
                </Grid>
            </SectionWrapper>
        </>
    );
    }}/>
);